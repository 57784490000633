export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'PieChartIcon',
    action: 'Ver Inicio',
    resource: 'Ver Inicio',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Ver usuarios',
  },
  {
    title: 'Roles y Permisos',
    route: 'roles',
    icon: 'LockIcon',
    action: 'read',
    resource: 'Ver roles',
  },
  {
    title: 'Planes',
    route: 'plans',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'plans.list',
  },
  {
    title: 'Cotizaciones',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'cotization.list',
    children: [
      {
        title: 'Nueva cotización',
        route: 'new-cotization',
        action: 'read',
        resource: 'cotization.store',
      },
      {
        title: 'Historial de vaucher',
        route: 'vaucher-history',
        action: 'read',
        resource: 'cotization.store',
      },
      {
        title: 'Historial de cotizaciones',
        route: 'cotization-history',
        action: 'read',
        resource: 'cotization.store',
      },
    ],
  },
  {
    title: 'Agencias',
    route: 'agencies',
    icon: 'GlobeIcon',
    action: 'read',
    resource: 'agencies.list',
  },
  {
    title: 'Modalidades',
    route: 'modalities',
    icon: 'MonitorIcon',
    action: 'read',
    resource: 'modalities.list',
  },
  {
    title: 'Reportes',
    route: 'reports',
    icon: 'BoxIcon',
    action: 'read',
    resource: 'reports.all',
  }
]
